<template>
	<div id="wrap">
		<div class="content">
			<div style="padding: 20px 0;" class="rsc">
				<span style="font-size: .9rem;">当前位置：</span>
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item>我的户口</el-breadcrumb-item>
					<el-breadcrumb-item>{{curTitle}}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>

			<div class="section">
				<div class="rbc">
					<div class="left wrap">
						<el-menu :default-active="menuIndex" class="el-menu-vertical-demo" @select="handleOpen"
							@close="handleClose">
							<el-menu-item :index="index + ''" v-for="(item, index) in menus" :key="index">
								<i :class="[item.icon ? item.icon : '']"> </i>
								<span slot="title">{{item.title}}</span>
							</el-menu-item>
						</el-menu>
					</div>

					<div class="right wrap">
						<keep-alive>
						    <router-view v-if="$route.meta.keepAlive"></router-view>
						</keep-alive>
						<router-view v-if="!$route.meta.keepAlive"></router-view>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	export default {
		created() {
			let index = this.menus.findIndex(item => item.link == this.$route.path)
			if (index > -1) this.menuIndex = index + ''
			if (this.menuIndex == '0') this.$go('/profile/myOrder')
		},
		data: () => ({
			menuIndex: '0',
			menus: [{
				icon: 'el-icon-s-order',
				title: '我的订单',
				link: '/profile/myOrder'
			}, {
				icon: 'el-icon-wallet',
				title: '我的余额',
				link: '/profile/myBalance',
			}, {
				icon: 'el-icon-coin',
				title: '我的积分',
				link: '/profile/myIntegral',
			}, {
				icon: 'el-icon-s-custom',
				title: '客户管理',
				link: '/profile/customer'
			}],
		}),
		computed: {
			curTitle() {
				return this.menus[this.menuIndex].title
			},
			// noMore() {
			// 	return this.count >= 5
			// },
			// disabled() {
			// 	return this.loading || this.noMore
			// }
		},
		watch: {
			menuIndex(val) {
				this.$go(this.menus[val].link)
			}
		},
		methods: {
			handleOpen(key, keyPath) {
				this.menuIndex = key
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
			// load() {
			// 	this.loading = true
			// 	setTimeout(() => {
			// 		this.count += 2
			// 		this.loading = false
			// 	}, 2000)
			// }
		}
	}
</script>

<style lang="scss" scoped>
	#wrap {
		background: #F5F5F5;
	}

	.content {
		width: 1200px;
		margin: 0 auto 60px;
	}

	.section {
		width: 1200px;
		min-height: 661px;
		margin: 18px auto 0;
		position: relative;
		z-index: 99;

		.wrap {
			height:880px;
			background: #fff;
			overflow: hidden;
		}

		.left {
			width: 185px;
			margin-right: 10px;

			.is-active {
				width: 100%;
				color: #fff;
				background: #3F7CF8;
			}

			.el-menu {
				border: none;
			}
		}

		.right {
			width: 1006px;
			padding: 20px 10px;

		}

		.el-menu-item {
			font-size: 1rem;
		}
	}
</style>
